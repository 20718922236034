<template>
  <div>
      <el-form
        :model="addForm"
        :rules="addFormRules"
        ref="addFormRef"
        label-width="auto"
      >
        <el-form-item label="姓名" prop="姓名" style="width: 80%;margin-top: 30px;">
          <el-input v-model="addForm.姓名" placeholder="请输入姓名"></el-input>
        </el-form-item>
        
        <el-form-item label="手机号" prop="手机号" style="width: 80%;">
          <el-input v-model="addForm.手机号" placeholder="请输入手机号（选填）"></el-input>
        </el-form-item>
        
        <el-form-item label="投诉内容" prop="内容" style="width: 95%;">
          <el-input type="textarea" v-model="addForm.内容" placeholder="请输入投诉内容" rows="4"></el-input>
        </el-form-item>
        
        <el-form-item>
            <el-button type="primary" @click="submitForm('addFormRef')">提交</el-button>
        </el-form-item>
      </el-form>
    
  </div>
</template>

<script>
export default {
  name: "plan",
  data() {
    var checkPhone = (rule, value, callback) => {
        if (!value) callback()

        const regex = /^1[3456789]\d{9}$/;//使用此正则即可
        if (!regex.test(value)) {
            callback(new Error('请输入正确手机格式'))
        } else {
            callback()
        }
    };
    return {
      addForm: {
        隶属id: this.$route.query.id,
        姓名: '',
        手机号: '',
        内容: ''
      },
      // 添加用户的表单的验证规则对象
      addFormRules: {
        姓名: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        手机号: [{ validator: checkPhone, message: "请输入合法手机号", trigger: "blur" }],
        内容: [
          { required: true, message: "请输入投诉内容", trigger: "blur" },
        ]
      },

    };
  },
  created() {
  },
  computed: {
  },
  methods: { 
    submitForm(formName) {
        let ref = this.$refs[formName]
        ref.validate((valid) => {
            if (!valid) return;
            
            this.$axios({
                method: "post",
                url: "/complaint/submit",
                data: this.addForm,
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((res) => {
                if (res.code == 200) {
                    this.$alert('投诉成功', '客户投诉', {
                        confirmButtonText: '确定',
                        callback: action => {
                            ref.resetFields()
                        }
                    });
                } else {
                    this.$message.warning(res.msg);
                }
            })
            .catch((err) => {
                this.$message.warning(err);
            });
        });
    },
  },
};
</script>

<style scoped>
</style>